import { render, staticRenderFns } from "./VFooter.vue?vue&type=template&id=c7c6cc54&"
import script from "./VFooter.vue?vue&type=script&lang=js&"
export * from "./VFooter.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Blocks: require('/home/cb3-be/components/global/Blocks.vue').default})
